html,
body {
	width: 100%;
	height: 100%;
	margin: 0;
	display: flex;
}

#root {
	width: 100%;
	height: 100%;
}

* {
	box-sizing: border-box;
}

.icon-button {
	width: 50px;
	height: 50px;
}

@media only screen and (min-width: 320px) {
	.font-small {
		font-size: 12px;
	}
	.font-default {
		font-size: 14px;
	}
}

@media only screen and (min-width: 375px) {
	.font-small {
		font-size: 16px;
	}
	.font-default {
		font-size: 17px;
	}
}

@media only screen and (min-width: 414px) {
	.font-small {
		font-size: 18px;
	}
	.font-default {
		font-size: 17px;
	}
}

.font-high {
	font-size: 24px;
}

.tamanho-botao-feedback {
	width: auto;
}
.texto-botao-feedback {
	display: initial;
}
@media only screen and (max-width: 600px) {
	.tamanho-botao-feedback {
		width: 100px;
	}
	.texto-botao-feedback {
		display: none;
	}
}

.circle-loading {
	display: inline-block;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	background-color: #1857c4;
}

.load-3 .circle-loading:nth-last-child(1) {
	animation: loadingC 0.6s 0.1s linear infinite;
}
.load-3 .circle-loading:nth-last-child(2) {
	animation: loadingC 0.6s 0.2s linear infinite;
}
.load-3 .circle-loading:nth-last-child(3) {
	animation: loadingC 0.6s 0.3s linear infinite;
}

@keyframes loadingC {
	0 {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(0, 15px);
	}
	100% {
		transform: translate(0, 0);
	}
}
